@charset "UTF-8";

/// Map deep get
/// @author Hugo Giraudel
/// @access public
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Key chain
/// @return {*} - Desired value
@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}

@function spanWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n - 1);
}

@function forceWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n - 1) !important;
}

@function offsetWidth($n) {
    @return $n * $fluidGridColumnWidth + $fluidGridGutterWidth * ($n + 1);
}
