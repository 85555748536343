.visible 		 { display: block !important; }
.visible-phone 	 { display: none !important; }
.visible-tablet  { display: none !important; }
.visible-desktop {} // Don't set initially
.hidden 		 { display: none !important; }
.hidden-phone 	 {} // Don't set initially
.hidden-tablet 	 {} // Don't set initially
.hidden-desktop  { display: none !important; }

@include respond-to(phone_landscape) {
	@for $i from 1 through $gridColumns {
		.span-phone-landscape#{$i} {
			width: spanWidth($i);
		}
	}
}

@include respond-to(phone) {
	.visible-phone    { display: inherit !important; }
	.hidden-phone	  { display: none !important; }
	.hidden-desktop   { display: inherit !important; }
	.visible-desktop  { display: none !important; }
	.text-left-phone  { text-align: left !important; }
	.text-right-phone { text-align: right !important; }
	.text-center-phone{ text-align: center !important; }
	.overflow-phone   { overflow: auto !important; }
	.no-margin-phone     { margin: 0 !important; }
	.margin-top-phone    { margin-top: $baseLineHeight !important; }
	.margin-bottom-phone { margin-bottom: $baseLineHeight !important; }

	@for $i from 1 through $gridColumns {
		.span-phone#{$i} {
			width: spanWidth($i);
			&.pushLeft,&.pushRight {
				width: spanWidth($i) + $fluidGridGutterWidth;
			}
		}
	}

	.grid-gutter-left-phone {
		margin-left: $fluidGridGutterWidth;
	}
	.grid-gutter-right-phone {
		margin-right: $fluidGridGutterWidth;
	}
	.align-center-phone {
		text-align: center !important;
	}

	.half-column-phone-left {
		float: left;
		width: 50%;
		text-align: left;
	}
	.half-column-phone-right {
		float: right;
		width: 50%;
		text-align: right;
	}
}

@include respond-to(tablet_portrait) {
	.visible-tablet    { display: inherit !important; }
	.hidden-tablet     { display: none !important; }
	.hidden-desktop    { display: inherit !important; }
	.visible-desktop   { display: none !important; }
	.text-left-tablet  { text-align: left !important; }
	.text-right-tablet { text-align: right !important; }
	.text-center-tablet { text-align: center !important; }

	@for $i from 1 through $gridColumns {
		.span-tablet#{$i} {
			width: spanWidth($i);
			&.pushLeft,&.pushRight {
				width: spanWidth($i) + $fluidGridGutterWidth;
			}
		}
	}
	@for $i from 1 through $gridColumns {
		.offset-tablet#{$i} {
			margin-left: offsetWidth($i);
		}
	}
}
