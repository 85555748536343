@import "variables";
@import "functions";

@mixin clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }
}

@mixin selfclear {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin focus-accessible{
    &:focus {
        outline: 2px solid $focusColor;
       .is-mobile & {
            outline: none;
       }
    }
}

@mixin hidden-accessible {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}
@mixin hide-text {
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
}

@mixin font-size($font-size) {
    font-size: $font-size +px;
    font-size: $font-size / $baseFontSize +rem;
}
@mixin font-size-line-height($font-size) {
    font-size: $font-size +px;
    font-size: $font-size / $baseFontSize +rem;
    line-height: $font-size + $lineHeightIncrement + px;
}

@mixin font-size-important($font-size) {
    font-size: $font-size +px !important;
    font-size: $font-size / $baseFontSize +rem !important;
}

@mixin head1($type: 'desktop') {
    font-family: $sansFontFamilyLight;
    @include font-size-line-height(map-deep-get($fontSizes, 'alpha', $type));
}

@mixin head2($type: 'desktop') {
    font-family: $sansFontFamilyLight;
    @include font-size-line-height(map-deep-get($fontSizes, 'beta', $type));
}

@mixin head3($type: 'desktop') {
    font-family: $sansFontFamilyLight;
    @include font-size-line-height(map-deep-get($fontSizes, 'gamma', $type));
}

@mixin head4($type: 'desktop') {
    font-family: $sansFontFamilyRegular;
    @include font-size-line-height(map-deep-get($fontSizes, 'delta', $type));
}

@mixin head5($type: 'desktop') {
    font-family: $baseFontFamily;
    @include font-size-line-height(map-deep-get($fontSizes, 'epsilon', $type));
}

@mixin head6($type: 'desktop') {
    font-family: $baseFontFamily;
    @include font-size-line-height(map-deep-get($fontSizes, 'zeta', $type));
    margin-bottom: 0;
}

@mixin font-small{
    font-family: $baseFontFamily;
    @include font-size($sm-size);
    line-height: $sm-lineHeight;
}

@mixin font-large {
    font-family: $sansFontFamilyLight;
    @include font-size($lg-size);
    line-height: $lg-lineHeight;
}

@mixin shadow-up {
    background-image: url(#{$americanImgPath}chrome/rebrand/shadow12-up.png);
    background-position: 50% bottom;
    background-repeat: no-repeat;
    background-size: 100% 4px;
}

@mixin shadow-down {
    background-image: url(#{$americanImgPath}chrome/rebrand/shadow12-down.png);
    background-position: 50% top;
    background-repeat: no-repeat;
    background-size: 100% 4px;
}

@mixin shadow-up-top {
    @include shadow-up;
    background-position: 50% top;
}

@mixin shadow-down-bottom {
    @include shadow-down;
    background-position: 50% bottom;
}

@mixin shadow-both {
    background-image: url(#{$americanImgPath}chrome/rebrand/shadow12-up.png), url(#{$americanImgPath}chrome/rebrand/shadow12-down.png);
    background-position: 50% bottom, 50% top;
    background-repeat: no-repeat;
    background-size: 100% 4px;

    .lt-ie9 & {
        border-top: 1px solid $lightGray;
        border-bottom: 1px solid $lightGray;
    }
}

@function base-line-height($size:'base') {
    $n: 1;
    @if ($size == 'xsmall') {
        $n: 0.25;
    }
    @else if ($size == 'small') {
        $n: 0.5;
    }
    @else if ($size == 'large') {
        $n: 1.5;
    }
    @else if ($size == 'xlarge') {
        $n: 2;
    }
    @return $baseLineHeight * $n;
}

@mixin superscript {
    vertical-align: super;
    position: relative;
}

@mixin vertical-align($position: relative) {
    position: $position;
    top: 50%;
    @include translateY(-50%);
}

@mixin margin($position:'all',$size:'base') {
    @if ($position == 'top') {
        margin-top: base-line-height($size);
    }
    @else if ($position == 'bottom') {
        margin-bottom: base-line-height($size);
    }
    @else if ($position == 'both') {
        margin-top: base-line-height($size);
        margin-bottom: base-line-height($size);
    }
    @else {
        margin: base-line-height($size);
    }
}
@mixin padding($position:'all',$size:'base') {
    @if ($position == 'top') {
        padding-top: base-line-height($size);
    }
    @else if ($position == 'bottom') {
        padding-bottom: base-line-height($size);
    }
    @else if ($position == 'both') {
        padding-top: base-line-height($size);
        padding-bottom: base-line-height($size);
    }
    @else {
        padding: base-line-height($size);
    }
}

@mixin hr-line($position:'both',$size:'base') {
    @if ($position == 'top') {
        border-top: 1px solid $lightGray;
    }
    @else if ($position == 'bottom') {
        border-bottom: 1px solid $lightGray;
    }
    @else {
        border-top: 1px solid $lightGray;
        border-bottom: 1px solid $lightGray;
    }
    @include margin($position,$size);
    @include padding($position,$size);
}

@mixin hr-dashed($position:'both',$size:'base') {
    @if ($position == 'top') {
        border-top: 1px dashed $lightGray;
    }
    @else if ($position == 'bottom') {
        border-bottom: 1px dashed $lightGray;
    }
    @else if ($position == 'middle') {
        border-top: 1px dashed $lightGray;
    }
    @else {
        border-top: 1px dashed $lightGray;
        border-bottom: 1px dashed $lightGray;
    }
    @include margin($position,$size);
    @include padding($position,$size);
}

@mixin hr-image($position:'both',$size:'base') {
    $image: url(#{$americanImgPath}chrome/rebrand/shadow-down.png);
    $image_top: $image no-repeat 50% top / 100% 5px;
    $image_bottom: $image no-repeat 50% bottom / 100% 5px;
    $image_top_ie: $image no-repeat 50% top;
    $image_bottom_ie: $image no-repeat 50% bottom;
    $image_both: $image_top, $image_bottom;
    $hc_border: 1px dotted transparent;    // A11Y: Used for high-contrast
    @if $position == 'top' {
        background: $image_top;
        border-top: $hc_border;
        .lt-ie9 & {
            background: $image_top_ie;
        }
    }
    @else if $position == 'bottom' {
        background: $image_bottom;
        border-bottom: $hc_border;
    }
    @else {
        background: $image_both;
        border-bottom: $hc_border;
        border-top: $hc_border;
        .lt-ie9 & {
            background: $image_top_ie;
            position: relative;
            &:before {
                background: $image_bottom_ie;
                position: absolute;
                content: " ";
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
           }
        }
    }
    @include margin($position,$size);
    @include padding($position,$size);
}

@mixin ie-10() {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        @content
    }
}

@mixin edge {
    @supports (-ms-user-select: none) {
      @content;
    }
}

@mixin microsoft-browsers($browsers...) {
    @each $browser in $browsers {
        @if ($browser == ie-10) {
            @include ie-10 {
              @content
            }
        } @else if ($browser == edge) {
            @include edge {
              @content
            }
        }
    }
}

//
// Mixins for gradients
//
@mixin gradient($default,$from,$to) {
    background: $default;
    @include filter-gradient($from, $to);
    @include background(linear-gradient($from, $to));
}
@mixin g-brandBlue {
    @include gradient($lightBlue, $lightBlue, $brandBlue);
}
@mixin gradient-lightBlue-darkBlue {
    @include gradient($brandBlue, $lightBlue, $darkBlue);
}

//
// Mixins for themes
//
@mixin theme-brandBlue-gradient {
    @include gradient-lightBlue-darkBlue;
    color: $white;

    a, h2, label {
        color: $white;
    }

    a {
        text-decoration: underline;
        &:focus, &:hover{
            text-decoration: none;
        }
        &:focus{
            outline: 1px solid $white;
        }
    }
}
@mixin theme-darkBlue {
    background-color: $darkBlue;
    color: $white;

    a, label {
        color: $white;
    }

    a {
        text-decoration: underline;
         &:focus, &:hover{
            text-decoration: none;
        }
        &:focus{
            outline: 1px solid $white;
        }
    }

    .info-note{
        color: white;
    }
}
@mixin theme-ultraLightGray {
    background-color: $ultraLightGray;
    @include padding('all');
    a {
        color: $detroitBlue;
    }
    h3,h4,h5,h6,.note {
        color: $darkGray;
    }
}
@mixin theme-superUltraLightGray {
    background-color: $superUltraLightGray;
    color: $darkGray;
    a {
        color: $detroitBlue;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
        &:focus{
            outline: 1px solid $detroitBlue;
            text-decoration: none;
        }
    }
}
@mixin theme-superUltraLightGray-gradient {
    @include gradient($superUltraLightGray,$superUltraLightGray,$white);
    @include padding('all');
    padding-bottom: 0px;
    h3,h4,h5,h6,.note {
        color: $darkGray;
    }
    a {
        color: $detroitBlue;
    }
}

@mixin theme-dialog-grayOverlay{
	&.ui-dialog{
		box-shadow: none;
		.ui-dialog-titlebar{
			background: #fff;
		}
	}
}

@mixin theme-mobiledialog-graybackground{
	background-color: $superUltraLightGray;
	.ui-dialog-titlebar{
		.ui-button{
			background-color: $superUltraLightGray;
		}
	}
	.ui-dialog-buttonpane{
		background-color: $superUltraLightGray;
	}
}

//
// Mixins for Responsive MediaQueries
//
@mixin respond-to($media) {
    /* Media: #{$media} */
    @if $media == phone_portrait {
        @media (max-width: 479px)  { @content; }
    }
    @else if $media == phone_small {
        @media (max-width: 320px)  { @content; }
    }
    @else if $media == phone_landscape {
        @media screen and (min-width: 480px) and (max-width: 767px) { @content; }
    }
    @else if $media == phone {
        @media screen and (max-width: 767px) { @content; }
    }
    @else if $media == tablet_portrait {
        @media screen and (min-width: 768px) and (max-width: 979px) { @content; }
    }
    @else if $media == tablet_portrait_down {
        @media screen and (max-width: 979px) { @content; }
    }
    @else if $media == tablet_landscape {
        @media screen and (min-width: 980px) and (max-width: 1032px) { @content; }
    }
    @else if $media == tablet_landscape_up {
        @media screen and (min-width: 980px) { @content; }
    }
    @else if $media == tablet {
        @media screen and (min-width: 768px) and (max-width: 1032px) { @content; }
    }
    @else if $media == tablet_down {    //phone-tablet
        @media screen and (max-width: 1032px) { @content; }
    }
    @else if $media == tablet_up {        //tablet-desktop
        @media screen and (min-width: 768px) { @content; }
    }
    @else if $media == desktop {
        @media screen and (min-width: 1033px) { @content; }
    }
    @else if $media == desktop_height_down {
        @media screen and (max-height: 740px) { @content; }
    }
}

@mixin hideAccessibleComponent() {
  border: 0;
  clip: rect(0 0 0 0);
  margin: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
}

@mixin rotate( $degrees ) {
  -webkit-transform: rotate(#{$degrees}deg);
  -moz-transform: rotate(#{$degrees}deg);
  -ms-transform: rotate(#{$degrees}deg);
  -o-transform: rotate(#{$degrees}deg);
  transform: rotate(#{$degrees}deg);
 }

 @mixin affix($top: 0, $marginBottom: 0) {
    margin-bottom: $marginBottom;
    position: -webkit-sticky;
    position: sticky;
    top: $top;
 }

 @mixin icon-button($top, $right) {
    top: $top;
    right: $right;
    position: absolute;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    color: $brandBlue;
    line-height: 15px;
    &:focus {
        outline: 2px solid $brandBlue;
        border: none;
    }
    .icon-locate::before {
        font-size: 18px;
    }
 }

 @mixin info-note() {
     font-family: $sansFontFamilyRegular;
     @include font-size-line-height($sm-size);
     color: $rangerGreen;
 }

 @mixin aileron-button($type: "primary"){
    @if $type == "primary" {
        background-color: $brandBlue;
        color: $white;
        padding: 6px 17px;
    }
    @if $type == "secondary" {
       background-color: $white;
       color: $brandBlue;
       border: 1px solid $brandBlue;
       padding: 5px 17px;
   }
   font-size: 1rem;
   font-family: $sansFontFamilyMedium;
   min-width: 76px;
   border-radius: 4px;
   text-align: center;
   line-height: 25px;
}

@mixin aileron-button-phone($type: "primary") {
    @include aileron-button($type);
    @if $type == "primary" {
        padding: 5px 6px;
    }
    @if $type == "secondary" {
        padding: 4px 6px;
    }
    font-size: 10px;
    line-height: 18px;
    min-width: 50px;
    min-height: 28px;
    margin: 12px 0;
 }
